<template>
   <el-col class="main">
      <Breadcrumb :crumbs="crumbs"></Breadcrumb>
      <el-row class="active">
         <el-row class="member">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>发行量</dt>
                  <dd>{{issueCount}}</dd>
               </dl>
            </el-col>
         </el-row>
         <el-row class="regularcustomer">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>使用量</dt><!--  曝光量-->
                  <dd>{{receiveNum}}</dd>
               </dl>
            </el-col>
         </el-row>
         <el-row class="usernumber">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>购买量</dt>
                  <dd>{{consumeNum}}</dd>
               </dl>
            </el-col>
         </el-row>
      </el-row>
      <el-row style="background-color: #ffffff; margin-top: 20px">
         <el-row class="">
            <el-row class="btm-title">预售券概况</el-row>
            <el-row class="chart" id="shop-like-bar" style="width: 90%; height: 360px; margin-top: 1rem; overflow: hidden;"></el-row>
         </el-row>
      </el-row>
      <el-row class="active">
         <el-row class="member">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>发行量</dt>
                  <dd>{{total}}</dd>
               </dl>
            </el-col>
         </el-row>
         <el-row class="regularcustomer">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>领取量</dt>
                  <dd>{{receiveCount}}</dd>
               </dl>
            </el-col>
         </el-row>
         <el-row class="usernumber">
            <el-row class="images"></el-row>
            <el-col class="content">
               <dl>
                  <dt>购买量</dt>
                  <dd>{{usedCount}}</dd>
               </dl>
            </el-col>
         </el-row>
      </el-row>
      <el-row style="background-color: #ffffff; margin-top: 20px">
         <el-row class="">
            <el-row class="btm-title">优惠券概况</el-row>
            <el-row class="chart" id="shop-like-bar2" style="width: 90%; height: 360px; margin-top: 1rem; overflow: hidden;"></el-row>
         </el-row>
      </el-row>
   </el-col>
</template>

<script>
import { mapState } from 'vuex';
import { urlObj } from '@/api/interface';
import { preSale  } from "@/api/interface/business";
export default {
   data(){
      return {
         crumbs: new Map([
            ['业务中台'], ['整体概况']
         ]),
         issueCount:'',    // 发行量
         receiveNum:'',    // 使用量
         consumeNum:'',    // 购买量
         total:'',         //发行量
         receiveCount:'',  //领取量
         usedCount:'',     //购买量
         options : {
            legend: {
               data: ['预售活动A', '预售活动B', '预售活动C', '预售活动D', '西安市','杭州市']
            },
            xAxis: {
               type: 'category',
               data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            },
            yAxis: {
               type: 'value'
            },
            series: [{
               name: '预售活动A',
               type: 'line',
               stack: '总量',
               data: [255, 144, 467, 325, 345, 642, 532,577,464,145,466,352,],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#ffa438', //改变折线点的颜色
                     lineStyle: {
                        color: '#ffa438' //改变折线颜色
                     }
                  }
               },
            },{
               name: '预售活动B',
               type: 'line',
               stack: '总量',
               data: [142, 565, 156, 325, 325, 354, 144,327,576,547,243,575],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#e94331', //改变折线点的颜色
                     lineStyle: {
                        color: '#e94331' //改变折线颜色
                     }
                  }
               },
            },{
               name: '预售活动C',
               type: 'line',
               stack: '总量',
               data: [654, 654, 156, 325, 658, 354, 644,241,364,547,568,575,],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#006fff', //改变折线点的颜色
                     lineStyle: {
                        color: '#006fff' //改变折线颜色
                     }
                  }
               },
            },{
               name: '预售活动D',
               type: 'line',
               stack: '总量',
               data: [142, 143, 104, 743, 543, 354, 355,577,576,756,243,346,],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#85daff', //改变折线点的颜色
                     lineStyle: {
                        color: '#85daff' //改变折线颜色
                     }
                  }
               },
            }
            ],
         },
         options1 : {
            legend: {
               data: ['7折优惠', '双11', '五一大放送', '国庆狂欢夜']
            },
            xAxis: {
               type: 'category',
               data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            },
            yAxis: {
               type: 'value'
            },
            series: [{
               name: '7折优惠',
               type: 'line',
               stack: '总量',
               data: [143, 243, 355, 533, 354, 466, 632,765,234,234,573,253],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#ffa438', //改变折线点的颜色
                     lineStyle: {
                        color: '#ffa438' //改变折线颜色
                     }
                  }
               },
            },{
               name: '双11',
               type: 'line',
               stack: '总量',
               data: [132, 245, 325, 546, 466, 676, 356,467,366,577,467,797],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#e94331', //改变折线点的颜色
                     lineStyle: {
                        color: '#e94331' //改变折线颜色
                     }
                  }
               },
            },{
               name: '五一大放送',
               type: 'line',
               stack: '总量',
               data: [132, 355, 466, 325, 355, 254, 124,325,222,143,754,235,],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#006fff', //改变折线点的颜色
                     lineStyle: {
                        color: '#006fff' //改变折线颜色
                     }
                  }
               },
            },{
               name: '国庆狂欢夜',
               type: 'line',
               stack: '总量',
               data: [313, 144, 214, 463, 125, 664, 353,353,325,646,235,255],
               smooth: true,
               itemStyle: {
                  normal: {
                     color: '#85daff', //改变折线点的颜色
                     lineStyle: {
                        color: '#85daff' //改变折线颜色
                     }
                  }
               },
            }
            ],
         },
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id && oldVal) {
               this.getPreSale()
               this.getCoupon()
            }
         },
         deep: true
      }
   },
   mounted(){
      this.getPreSale()
      this.getCoupon()
      let myChart = this.$echarts.init(document.getElementById('shop-like-bar'));
      myChart.setOption(this.options);
      let myChart2 = this.$echarts.init(document.getElementById('shop-like-bar2'));
      myChart2.setOption(this.options1);
      window.onresize=function(){
         myChart2.resize()
         myChart.resize()
      }
   },
   methods: {
      //获取预售券数量
      getPreSale(){
         const url = preSale.getCouponPresellSellDetail
         let param = {
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url,param,).then(res => {
            if (res.success) {
               if(res.hasOwnProperty('records')){
                  let data = res.records
                  this.issueCount = data.issueCount
                  this.receiveNum = data.receiveNum
                  this.consumeNum = data.consumeNum
               }else{
                  this.issueCount = 0
                  this.receiveNum = 0
                  this.consumeNum = 0
               }
            }
         })
      },
      // 获取优惠券数量
      getCoupon(){
         const url = preSale.couponStatistics
         let param = {
            hotelId:this.hotelInfo.id
         }
         this.$axios.post(url,param).then(res => {
            if (res.success) {
               let data = res.records
               this.total = data.total || 0
               this.receiveCount = data.receiveCount || 0
               this.usedCount = data.usedCount || 0
            }
         })
      }
   }
}
</script>

<style scoped>
#user-visit-line{
   width: 90%;
   height: 300px
}
.btm-title{
   line-height: 60px; background: #f7f7f7; font-size: 16px;
   padding-left: 30px;
}

.main{width: 100%; }
.title{width: 98%; margin: auto; margin-top: 2rem; padding-left: 1rem;}
.optiondate{float: left;}
.tiem{float: right;}
.active{width: 98%; height: 200px; margin: auto; margin-top: 2rem; display: flex; box-shadow: 0 0 20px #dce7f9; padding: 1rem}
/*.active .el-row{width: 32%; height: 100%; margin-left: 2%; border-radius: 1%;}*/
.active .member{background: #0272ff; margin-left: 0; display: flex; width: 32%; height: 100%; border-radius: 1%;}
.member .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") 0 0 no-repeat; }
.active .regularcustomer{background: #4ad7b7; width: 32%; height: 100%; margin-left: 2%; border-radius: 1%; display: flex;}
.regularcustomer .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") -99px 0 no-repeat;}
.active .usernumber{background: #ea4b33; width: 32%; height: 100%; margin-left: 2%; border-radius: 1%; display: flex}
.usernumber .images{width: 100px; height: 100px; border: 1px solid white; border-radius: 50%; margin-left: 10%; margin-top: 7%; background: url("../../../assets/lALPBF8a8Tu4p_JkzQEs_300_100.png") -200px 0 no-repeat;}
.content{width: 50%;}
.content dl{margin-top: 13%;margin-left: 33%; line-height: 50px;}
.content dl dt{font-size: 20px; color: white;}
.content dl dd{font-size: 20px; color: white;}

/*.numberofactiveusers{width: 98%; margin: auto; margin-top: 2rem; height: 400px; padding-bottom: 1rem;}*/
/*.accesstime{width: 49%; height: 100%; box-shadow: 0 0 20px #dce7f9; float: left;}*/
/*.visittimes{width: 49%; height: 100%; box-shadow: 0 0 20px #dce7f9; float:right;}*/
/*.visittimes .table{ overflow-y: auto; height: 280px }*/

/*.titles{width: 100%; height: 60px; line-height: 60px; padding-left: 1rem;}*/
/*.ofactiveusers{font-size: 14px; padding-left: 1rem; float: left; width: 50%;}*/
/*.tiem{float: right; width: 50%; text-align: right;}*/
/deep/ .has-gutter tr th{text-align: center;}
/deep/ tbody .el-table__row td{text-align: center}
/deep/.el-table{margin-top: 2.6rem;}

/*.retentionrate{width: 98%;margin: auto; margin-top: 2rem; height: 500px; padding-left: 1rem; box-shadow: 0 0 20px #dce7f9;}*/
/*.onlineusers{font-size: 14px; width: 49%; height: 100%; float: left; padding: 2rem 0; margin-left: 1rem;}*/
</style>
